
const routes = [
    { path: '/', component: () => import('./components/Worklist.vue') },
    { path: '/logout', component: () => import('./components/Logout.vue') },
    { path: '/help', component: () => import('./components/Help.vue') },
    { path: '/report', component: () => import('./components/Report.vue') },
    { path: '/submit_scan_dicom', component: () => import('./components/SubmitScanDicom.vue') },
    { path: '/submit_scan_nifti', component: () => import('./components/SubmitScanNifti.vue') },
    { path: '/worklist', component: () => import('./components/Worklist.vue') },
];

export default routes;