<template>
    <div>
      <b-modal ref="noticeDialog" title="Notice" size="xl" 
        header-bg-variant="warning" header-text-variant="dark"
        body-bg-variant="light" body-text-variant="dark"
        footer-bg-variant="light" footer-text-variant="dark"
        content-class="shadow"
        no-close-on-esc no-close-on-backdrop hide-header-close
        cancel-title="Reject" @cancel="handleCancel"
        ok-title="Accept" @ok="handleOK">
        <iframe src="NOTICE.html" :style="iframeStyle"/>
      </b-modal>
    </div>
</template>
<script>

import worklistFns from '../common/worklistFns.js'

export default {
    components: {
    },
    data() {
        return {
            iframeHeight: 200,
        }
    },
    computed: {
        iframeStyle() {
            return `height: ${this.iframeHeight}px; width: 100%;`
        }
    },
    methods: {
        show() {
            this.iframeHeight = window.innerHeight - 240;
            this.$refs.noticeDialog.show()
        },
        handleCancel() {
            this.$refs.noticeDialog.hide()
            this.$router.replace('logout')
        },
        handleOK() {
            worklistFns.set_notice_accept_time(this.$store.state.keycloak.tokenParsed.preferred_username)
            this.$refs.noticeDialog.hide()
            this.$emit('accepted')
        },
    }
}    
</script>
<style scoped>
</style>