<template>
  <div>
    <DashboardNavBar/>
    <b-modal ref="submitDialog" title="Scan Upload"
      size="lg" 
      header-bg-variant="light" header-text-variant="dark"
      body-bg-variant="light" body-text-variant="dark"
      footer-bg-variant="light" footer-text-variant="dark"
      content-class="shadow" ok-only
      :ok-disabled="submitDialogDisabled">
        <b-alert :show="submitAlert1" variant="primary">
          <b-badge variant="primary">Queued: {{numQueued}}</b-badge>&nbsp; 
          <b-badge variant="success">Uploaded: {{numOK}}</b-badge>&nbsp;
          <b-badge v-if="numErrors > 0" variant="warning">Errors: {{numErrors}}</b-badge>
        </b-alert>
        <pre style="height: auto; max-height: 200px; overflow-x: auto;">{{submitProgressMsg}}</pre>
        <b-alert :show="uploadDoneShow" :variant="uploadDoneVariant">{{uploadDoneMsg}}</b-alert>
    </b-modal>
    <b-container fluid class="bg-custom">
      <b-row>
        <b-col>
          <span class="h1">Submit Scan (NifTI-1)</span>
          <b-form :validated="true">
            <b-container fluid class="px-2 py-2">
              <b-row>
                  <b-col cols="9">
                    <b-alert show>Select NifTI-1 file to submit for processing.  
                    <b-badge v-if="selectedFile!=null" variant="secondary">File selected.</b-badge>
                  </b-alert>
                  </b-col>
                  <b-col class="text-right" cols="3">
                    <b-button id="submitButton" :disabled="!canSubmit" variant="primary" @click="handleSubmit()"><feather type="upload-cloud"></feather>&nbsp;Submit</b-button>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col>
                    <b-form-file v-model="selectedFile" required>
                    </b-form-file>
                  </b-col>
              </b-row>
              <b-row>
                <b-col class="border border-dark rounded mt-1 p-1">
                  <strong>SCAN INFORMATION</strong>
                  <b-container class="p-1 m-1">
                    <b-row>
                      <b-col cols="2" class="text-right">
                        <strong>Patient Name:</strong>
                      </b-col>
                      <b-col cols="4">
                        <b-form-input v-model="patient_name_f" name="patient_name_f" placeholder="<first name>" v-validate="'required|min:1'" required />
                      </b-col>
                      <b-col cols="2">
                        <b-form-input v-model="patient_name_m" name="patient_name_m" placeholder="<middle>" />
                      </b-col>
                      <b-col cols="4">
                        <b-form-input v-model="patient_name_l" name="patient_name_l" placeholder="<last name>" v-validate="'required|min:1'" required />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="2" class="text-right">
                        <strong>Patient ID:</strong>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input v-model="patient_id" name="patient_id" placeholder="<patient ID>" v-validate="'required|min:1'" required />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="2" class="text-right">
                        <strong>Patient DOB:</strong>
                      </b-col>
                      <b-col cols="10">
                        <b-form-datepicker v-model="patient_dob" name="patient_dob" :state="patientDobValid" required />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="2" class="text-right">
                        <strong>Patient Sex:</strong>
                      </b-col>
                      <b-col cols="10">
                        <b-form-select v-model="patient_sex" name="patient_sex" :state="patientSexValid" required >
                          <b-form-select-option value="">Select…</b-form-select-option>
                          <b-form-select-option value="F">Female</b-form-select-option>
                          <b-form-select-option value="M">Male</b-form-select-option>
                        </b-form-select>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="2" class="text-right">
                        <strong>Study Date:</strong>
                      </b-col>
                      <b-col cols="10">
                        <b-form-datepicker v-model="study_date" name="study_date" :state="studyDateValid" required />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="2" class="text-right">
                        <strong>Accession Number:</strong>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input v-model="acc_num" name="acc_num" placeholder="<accession number>" v-validate="'required|min:1'" required />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="2" class="text-right">
                        <strong>Study Description:</strong>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input v-model="study_desc" name="study_desc" placeholder="<study description>" />
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="2" class="text-right">
                        <strong>Scanner Vendor:</strong>
                      </b-col>
                      <b-col cols="10">
                        <b-form-select v-model="scanner_vendor" name="scanner_vendor" :state="scannerVendorValid" required >
                          <b-form-select-option value="" selected>Select...</b-form-select-option>
                          <b-form-select-option value="AllTech">AllTech Medical Systems</b-form-select-option>
                          <b-form-select-option value="Canon">Canon Medical Systems</b-form-select-option>
                          <b-form-select-option value="GE">GE Healthcare</b-form-select-option>
                          <b-form-select-option value="Hitaci">Hitaci</b-form-select-option>
                          <b-form-select-option value="Neusoft">Neusoft</b-form-select-option>
                          <b-form-select-option value="Philips">Philips</b-form-select-option>
                          <b-form-select-option value="Siemens">Siemens</b-form-select-option>
                          <b-form-select-option value="Time">Time Medical Systems</b-form-select-option>
                          <b-form-select-option value="Toshiba">Toshiba</b-form-select-option>
                          <b-form-select-option value="United Imaging">United Imaging Healthcare</b-form-select-option>
                          <b-form-select-option value="Unknown">Unknown/Other</b-form-select-option>
                          <b-form-select-option value="Wandong">Wandong Medical Technology</b-form-select-option>
                        </b-form-select>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="2" class="text-right">
                        <strong>Scanner Field Strength:</strong>
                      </b-col>
                      <b-col cols="10">
                        <b-form-select v-model="scanner_b0" name="scanner_b0" :state="scannerB0Valid" required >
                          <b-form-select-option value="">Select…</b-form-select-option>
                          <b-form-select-option value="T15">1.5 T</b-form-select-option>
                          <b-form-select-option value="T30">3.0 T</b-form-select-option>
                          <b-form-select-option value="Unknown">Unknown</b-form-select-option>
                        </b-form-select>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <b-alert show variant="info">The scan information above will be cached on this device and used to populate elements in the worklist and report.</b-alert>
                </b-col>
              </b-row>
            </b-container>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'
import DashboardNavBar from './DashboardNavBar.vue'
import admdx from '../common/admdx.js'
import worklistFns from '../common/worklistFns.js'

export default {
  name: 'worklist',
  components: {
    DashboardNavBar
  },
  data() {
    return {
      elementsForWorklist: require('../common/dcm_elements_worklist.json'),

      // Scan Upload State Machine
      //
      scanData: {},
      scanId: '',
      numOK: 0,
      numErrors: 0,
      submitDialogDisabled: true,
      submitStep: 0,
      submitAlert1: false,
      uploadDoneShow: false,
      uploadDoneVariant: "success",
      uploadDoneMsg: "Upload Complete.",
      uploadProgress: false,
      submitProgressMsg: '',
      uploadProgressMsg: '',

      // Scan Inputs
      //
      selectedFile: null,
      acc_num: '',
      patient_name_f: '',
      patient_name_m: '',
      patient_name_l: '',
      patient_id: '',
      patient_dob: '',
      patient_sex: '',
      scanner_vendor: '',
      scanner_b0: '',
      study_date: '',
      study_desc: 'Brain Scan (NifTI-1 format)',
    }
  },
  computed: {
    patientDobValid() {
      return (this.patient_dob != '')
    },
    patientSexValid() {
      return (this.patient_sex != '')
    },
    scannerB0Valid() {
      return (this.scanner_b0 != '')
    },
    scannerVendorValid() {
      return (this.scanner_vendor != '')
    },
    studyDateValid() {
      return (this.study_date != '')
    },
    canSubmit() {
      const errList = this.errors.all()
      var okay = (errList.length == 0) 
      okay &= (this.selectedFile != null)
      okay &= this.patientDobValid
      okay &= this.patientSexValid
      okay &= this.studyDateValid
      
      return okay
    },
    numQueued() {
      return Math.max(0, 1 - this.numOK - this.numErrors)
    }
  },
  methods: {
    getScanId() {
      this.$log.debug('POST '+this.$configs.endpointsBaseUrl+'/ScanId')
      let data = {
      }
      let opts = {
        headers: {
            Authorization: 'Bearer '+this.$store.state.keycloak.token
        }
      }
      axios.post(this.$configs.endpointsBaseUrl+'/ScanId', data, opts)
      .then(response => {
        this.scanId = response.data.scanId
        this.$log.debug("this.scanId="+this.scanId)
        this.submitProgressMsg += '\nScan identifier received.'
        this.submitProgressMsg += '\nUploading file…'
        this.uploadFile()
      })
      .catch(err => {
        this.$log.error("Error requesting new scan ID: "+err.message)
        this.submitDialogDisabled = false
        this.submitProgressMsg += `\nUnable to get scan identifier.`
        this.uploadDoneMsg = 'Upload done with error(s).'
        this.uploadDoneVariant = "warning"
        this.uploadDoneShow = true
      })
    },
    uploadFile() {
      const file = this.selectedFile
      var fileName = file.name
      this.fileSize = file.size
      this.$log.debug("fileSize="+this.fileSize)
      this.fileUploaded = 0
      var reader = new FileReader();
      var _this = this
      reader.onerror = function(/*file*/) {
        _this.$log.error("Error reading file="+fileName)
        reader.abort();
      };

      reader.onload = function(/*file*/) {
        _this.$log.debug("Read file="+fileName+" suffix="+fileName.split('.').pop())
        let promise = new Promise((resolve, reject) => {
          const patientDOB = admdx.toDicomDate(_this.patient_dob);
          const studyDate = admdx.toDicomDate(_this.study_date);
          var age = admdx.getAge(patientDOB, studyDate);
          let endpoint = '/UploadNifti/'+encodeURIComponent(_this.scanId)
          let formData = new FormData()
          formData.append('object', new Blob([reader.result]), fileName)
          formData.append('suffix', fileName.split('.').pop())
          formData.append('patient_age', age)
          formData.append('patient_gender', _this.patient_sex)
          formData.append('scanner_vendor', _this.scanner_vendor)
          formData.append('scanner_b0', _this.scanner_b0)
          axios.put(
            endpoint,
            formData,
            {
              baseURL: _this.$configs.endpointsBaseUrl,
              headers: {
                Authorization: 'Bearer '+_this.$store.state.keycloak.token,
                'Content-Type': 'multipart/form-data'
              }
            }
          )
          .then(response => {
            _this.$log.debug(response)
            resolve(response)
          })
          .catch(err => {
            _this.$log.error("UploadNifti error: "+err.message)
            reject(err)
          })
        })

        promise.then(() => {
          _this.$log.debug("Uploaded file="+fileName)
          _this.numOK++
        })
        .catch(err => {
          _this.$log.error("Error importing object: "+err.message)
          _this.numErrors++
          _this.uploadError()
        })
        .finally(async () => {
          _this.uploadFileDone()

          // Add entry to worklist for this scan...
          //
          let scanData = {}
          scanData["format"] = 'NifTI-1';
          scanData["upload_anon"] = false;
          scanData["x00100010"] = _this.patient_name_l + "^" + _this.patient_name_f + "^" + _this.patient_name_m + "^^";
          scanData["x00100020"] = _this.patient_id;
          scanData["x00100030"] = admdx.toDicomDate(_this.patient_dob);
          scanData["x00080020"] = admdx.toDicomDate(_this.study_date)
          scanData["x00080030"] = '000000'; // +TODO+ Add to form (study time)
          scanData["x00080050"] = _this.acc_num;
          scanData["x00080060"] = 'MR';
          scanData["x00081030"] = _this.study_desc
          let userId = _this.$store.state.keycloak.tokenParsed.preferred_username
          await worklistFns.worklist_add_scan(userId, _this.scanId, scanData)
        })
      }
      reader.readAsArrayBuffer(file)
    },
    handleInputsState() {
      this.$log.debug('handleInputsState')
    },
    handleSubmit() {
      this.$log.debug('handleSubmit')
      var rc = false;
      this.numOK = 0;
      this.numErrors = 0;
      this.submitStep = 1;
      
      this.submitDialogDisabled = true
      this.submitProgressMsg = ''
      this.uploadProgressMsg = '';
      this.submitAlert1 = true
      this.uploadDoneShow = false
      this.uploadProgress = true

      if (this.selectedFile != null)
      {
        this.submitDialogDisabled = true
        this.$refs.submitDialog.show();
        this.submitProgressMsg = 'Requesting new scan identifier…';
        this.getScanId();
      }
      return rc;
    },
    uploadError() {
      this.submitProgressMsg += `\nError during upload of file.`
    },
    uploadFileDone() {
      let _this = this
      this.submitProgressMsg += '\nRequesting job to process uploaded scan…'
      let promise = new Promise((resolve, reject) => {
        let endpoint = '/UploadDone/'+encodeURIComponent(_this.scanId)
        let formData = new FormData()
        axios.put(
          endpoint,
          formData,
          {
            baseURL: _this.$configs.endpointsBaseUrl,
            headers: {
              Authorization: 'Bearer '+_this.$store.state.keycloak.token
            }
          }
        )
        .then(response => {
          _this.$log.debug(response)
          resolve(response)
        })
        .catch(err => {
          _this.$log.error("UploadDone axios error: "+err.message)
          reject(err)
        })
      })

      let uploadOkay = (this.numErrors == 0)
      promise.then(() => {
        _this.$log.debug('UploadDone success')
        _this.submitProgressMsg += `\nJob queued to process uploaded scan.`
      })
      .catch(err => {
        _this.$log.error("UploadDone promise error : "+err.message)
        _this.submitProgressMsg += `\nError requesting job to process uploaded scan.`
        uploadOkay = false
      })
      .finally(() => {
        _this.submitDialogDisabled = false
        if (uploadOkay) {
          _this.uploadDoneMsg = 'Upload done.'
          _this.uploadDoneVariant = "success"
        }
        else {
          _this.uploadDoneMsg = 'Upload done with error(s).'
          _this.uploadDoneVariant = "warning"
        }
        _this.uploadDoneShow = true
        _this.selectedFile = null
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-custom {
  background-color: #d0d6e2;
}
.bg-custom2 {
  background-color: #3b465e
}
</style>
