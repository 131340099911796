<template>
  <div>
    <DashboardNavBar/>
    <b-container fluid class="bg-custom">
      <b-row>
        <b-col class="p-1">
<div>
  <span class="h1">Help</span>
</div>
<p>For support questions or issues, please contact 
<a :href="supportEmailUrl">{{supportEmail}}</a>.</p>
<div class="h2">Third-Party Software</div>
<p>This application leverages the following open source projects:</p>
<ul class="list-group">
<li class="list-group-item list-group-item-secondary"><a class="text-dark" href="http://getbootstrap.com" target="_help">Bootstrap 4</a>&nbsp;<a class="badge badge-light" href="http://getbootstrap.com/docs/4.1/about/license/" target="_help">License (MIT)</a></li>
<li class="list-group-item list-group-item-primary"><a class="text-dark" href="https://www.datatables.net" target="_help">DataTables</a>&nbsp;<a class="badge badge-light" href="https://datatables.net/license/mit" target="_help">License (MIT)</a></li>
<li class="list-group-item list-group-item-secondary"><a class="text-dark" href="https://www.cornerstonejs.org" target="_help">dicomParser (Cornerstone.js)</a>&nbsp;<a class="badge badge-light" href="https://github.com/cornerstonejs/dicomParser/blob/master/LICENSE" target="_help">License (MIT)</a></li>
<li class="list-group-item list-group-item-primary"><a class="text-dark" href="https://feathericons.com/" target="_help">Feather Icons</a>&nbsp;<a class="badge badge-light" href="https://github.com/colebemis/feather/blob/master/LICENSE" target="_help">License (MIT)</a></li>
<li class="list-group-item list-group-item-secondary"><a class="text-dark" href="https://fineuploader.com/" target="_help">Fine Uploader</a>&nbsp;<a class="badge badge-light" href="fine-uploader/LICENSE" target="_help">License (MIT)</a></li>
<li class="list-group-item list-group-item-primary"><a class="text-dark" href="https://sourceforge.net/projects/gdcm/" target="_help">GDCM</a>&nbsp;<a class="badge badge-light" href="https://github.com/malaterre/GDCM/blob/master/Copyright.txt" target="_help">License</a></li>
<li class="list-group-item list-group-item-secondary"><a class="text-dark" href="http://jquery.com" target="_help">jQuery</a>&nbsp;<a class="badge badge-light" href="http://jquery.org/license/" target="_help">License (MIT)</a></li>
<li class="list-group-item list-group-item-primary"><a class="text-dark" href="https://jquery-form.github.io/form/" target="_help">jQuery Form Plugin</a>&nbsp;<a class="badge badge-light" href="https://github.com/jquery-form/form/blob/master/LICENSE-MIT" target="_help">License (MIT)</a></li>
<li class="list-group-item list-group-item-secondary"><a class="text-dark" href="https://mdbootstrap.com/" target="_help">Material Design for Bootstrap (Free)</a>&nbsp;<a class="badge badge-light" href="https://mdbootstrap.com/license/" target="_help">License (MIT)</a></li>
<li class="list-group-item list-group-item-primary"><a class="text-dark" href="https://pdf-lib.js.org/" target="_help">PDF-LIB</a>&nbsp;<a class="badge badge-light" href="https://github.com/Hopding/pdf-lib/blob/master/LICENSE.md" target="_help">License (MIT)</a></li>
<li class="list-group-item list-group-item-secondary"><a class="text-dark" href="https://pydicom.github.io" target="_help">pydicom</a>&nbsp;<a class="badge badge-light" href="https://github.com/pydicom/pydicom/blob/master/LICENSE" target="_help">License</a></li>
</ul>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import DashboardNavBar from './DashboardNavBar.vue'

export default {
  name: 'worklist',
  components: {
    DashboardNavBar
  },
  computed: {
    supportEmail() {
      return this.$configs[ 'supportEmail' ];
    },
    supportEmailUrl() {
      return "mailto:" + this.supportEmail
    }
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-custom {
  background-color: #d0d6e2;
}
.bg-custom2 {
  background-color: #3b465e
}
</style>
