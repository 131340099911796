<template>
  <div>
    <DashboardNavBar/>
    <a ref="reportDownloadLink" target="_blank"></a>
    <b-container fluid class="bg-custom">
      <b-row>
        <b-col class="p-1">
            <span class="h1">Report</span>
        </b-col>
        <b-col class="p-1 text-right">
            <b-button v-if="reportBuffer!=''" class="ml-2 align-top" @click="handleDownload" variant="secondary" title="Download Report" :disabled="pageCount == 0">
                <b-icon icon="cloud-download"></b-icon>
            </b-button>
            <b-button class="ml-2 align-top" to="worklist">Close</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
            <div v-if="(reportBuffer == '') && !reportError" class="alert alert-info" role="alert">
                Loading report...
            </div>
            <div v-if="reportError" class="alert alert-danger" role="alert">
                Unable to load report.
            </div>
            <div v-if="pageCount > 0" :style="pdfParentDivStyle">
                <div class="mt-1 pdfDiv" >
                    <div v-for="i in pageCount" :key="i">
                        <b-badge variant="secondary" show size="sm">Page {{i}}</b-badge>
                        <pdf :src="reportTask" :page="i"></pdf>
                    </div>
                </div>
            </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import DashboardNavBar from './DashboardNavBar.vue'
import axios from 'axios'
import pdf from 'vue-pdf'
import {PDFDocument, rgb, StandardFonts} from 'pdf-lib'
import worklistFns from '../common/worklistFns'

export default {
    name: 'report',
    components: {
        DashboardNavBar,
        pdf
    },
    data() {
        return {
            currentPage: 1,
            requestedPage: 1,
            pageCount: 0,
            reportBuffer: '',
            reportError: false,
            reportTask: null,
            pdfDivHeight: '100px'
        }
    },
    created() {
        window.addEventListener("resize", this.handleResize);
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    mounted() {
        this.handleResize()
        this.show()
    },
    computed: {
        pdfParentDivStyle() {
            return "height: "+this.pdfDivHeight+"px;"
        }
    },
    methods: {
        show() {
            this.reportBuffer = ''
            this.requestedPage = 1
            this.reportError = false
            this.pageCount = 0
            this.reportTask = null

            // Load PDF for report
            //
            this.$log.debug('Getting report for scanId='+this.$store.state.reportScan.scanID)
            let opts = {
                headers: {
                    Authorization: 'Bearer '+this.$store.state.keycloak.token
                },
                responseType: 'arraybuffer'
            }
            axios.get(this.$configs.endpointsBaseUrl+'/Report/' + encodeURIComponent(this.$store.state.reportScan.scanID), opts)
            .then((response) => {
                if (response.data != null) {
                    this.$log.debug("Read report.")
                    this.reportBuffer = response.data
                    this.modifyPdf()
                    .then(() => {
                        this.reportTask = pdf.createLoadingTask({ data: this.reportBuffer })
                        this.reportTask.promise.then(pdf => {
                            this.pageCount = pdf.numPages
                            this.handleResize()
                        })
                    })
                }
                else {
                    this.$log.debug("Document null or empty.")
                    this.reportError = true
                    this.handleResize()
                }
            })
            .catch(err => {
                this.$log.error("Error fetching report, err=: "+err.message)
            })
        },
        handleDownload(/*event*/) {
            let reportBlob = new Blob([this.reportBuffer], {type: 'application/pdf'})
            let reportUrl = URL.createObjectURL(reportBlob)
            this.$refs.reportDownloadLink.href = reportUrl
            this.$refs.reportDownloadLink.click()
        },
        handleResize(/*event*/) {
            if (this.reportError || (this.reportBuffer == '')) {
                this.pdfDivHeight = 0
            }
            else {
                this.pdfDivHeight = window.innerHeight - 125
                this.$log.debug(`Setting pdfDivHeight=${this.pdfDivHeight}`)
            }
        },
        modifyPdf() {
            // +TODO+ Handle more than CorInsightsMRI_v1.2 template.
            //
            let _this = this
            return new Promise((resolve, reject) => {
                if (_this.$configs.reportAddStudyInfo) {
                    PDFDocument.load(_this.reportBuffer)
                    .then(pdfDoc => {
                        var patientName = "PATIENT NAME HERE";
                        var patientID = "PATIENT ID HERE";
                        var studyDate = "STUDY DATE HERE";
                        try
                        {
                            patientName = worklistFns.format_dcm_name( _this.$store.state.reportScan["scanData"]["x00100010"] );
                            patientID = worklistFns.format_dcm_str( _this.$store.state.reportScan["scanData"]["x00100020"] );
                            var dateValue = _this.$store.state.reportScan["scanData"]["x00080020"];
                            var y = dateValue.substring( 0, 4 );
                            var m = dateValue.substring( 4, 6 );
                            var d = dateValue.substring( 6, 8 );
                            studyDate = m + "/" + d + "/" + y
                        }
                        catch (err)
                        {
                            _this.$log.debug("Error parsing scan data: "+err);
                        }

                        const pages = pdfDoc.getPages()
                        _this.$log.debug('numPages=' + pages.length)

                        pdfDoc.embedFont(StandardFonts.Helvetica) // Arial
                        .then((arialFont) => {
                            for( var i = 0; i < pages.length; i++ ) {
                                console.log('Updating page '+i);
                                const pageToModify = pages[i]
                                const { height } = pageToModify.getSize()

                                // +TODO+ Make elements to draw configuration to allow for
                                // differences between report template versions (scan data
                                // elements along with where to draw in report and font parameters).
                                //
                                if (_this.$configs.reportAddStudyInfo) {
                                    pageToModify.drawText(patientName, {
                                        x: 78,
                                        y: height - 36,
                                        size: 11,
                                        font: arialFont,
                                        color: rgb(0.0, 0.0, 0.0)
                                    });
                                    pageToModify.drawText(patientID, {
                                        x: 78,
                                        y: height - 50,
                                        size: 11,
                                        font: arialFont,
                                        color: rgb(0.0, 0.0, 0.0)
                                    });
                                    pageToModify.drawText(studyDate, {
                                        x: 292,
                                        y: height - 50,
                                        size: 10.5,
                                        font: arialFont,
                                        color: rgb(0.0, 0.0, 0.0)
                                    });
                                }
                            }

                            pdfDoc.save()
                            .then(pdfBytes => {
                                _this.reportBuffer = pdfBytes
                                _this.$log.debug('modifyPdf done')
                                resolve(true)
                            })
                        })
                    })
                    .catch(e => {
                        this.$log.debug("ERROR modifyPdf: "+e.message)
                        reject(e.message)
                    })
                }
                else {
                    // No need to update report buffer.
                    //
                    resolve(true)
                }
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-custom {
  background-color: #d0d6e2;
}
.pdfDiv {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
