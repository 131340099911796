<template>
  <div>
    <p>Logging out...</p>
  </div>
</template>

<script>

export default {
  name: 'logout',
  components: {
  },
  data() {
    return {
    };
  },
  mounted() {
      try {
        this.$log.info('Handling logout');
        var redirectUrl = window.location.origin + "/index.html"
        var keycloakOptions = {
          "redirectUri": redirectUrl
        }
        this.$store.state.keycloak.logout(keycloakOptions)
      }
      catch(err) {
        this.$log.error('Failed to logout: '+err);
        window.location = window.location.origin + "/index.html"
      }
  },
  methods: {
  }
};
</script>
<style scoped>
</style>