<template>
  <div class="bg-dark">
    <div v-if="!authorizedUser" class="text-white text-center">
      <p><strong>Current user does not have access to this application.</strong></p>
      <p><button @click="logout" variant="primary">Switch User</button></p>
      <p>Contact {{$configs.supportEmail}} if access required.</p>
    </div>
    <router-view v-if="authorizedUser"/>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  computed: {
    authorizedUser() {
      return this.$store.state.keycloak.hasRealmRole('portal_user')
    }
  },
  methods: {
    logout() {
      try {
        this.$log.info('Handling logout');
        var redirectUrl = window.location.origin + "/index.html"
        var keycloakOptions = {
          "redirectUri": redirectUrl
        }
        this.$store.state.keycloak.logout(keycloakOptions)
      }
      catch(err) {
        this.$log.error('Failed to logout: '+err);
        window.location = window.location.origin + "/index.html"
      }
    }
  }
}
</script>

<style>
</style>
